/* eslint-env browser */
import angular from "angular";
import * as d3 from "d3";
import moment from "moment";

import {formatDateAndTime} from "../../components/DateAndTime/DateAndTime.jsx";

/* @ngInject */
export default class AverageHeartRateGraphController {
  constructor($document, $injector) {
    this._$document = $document;
    this._excludeArtifactService = $injector.get("ExcludeArtifactService");
    this._$mdDialog = $injector.get("$mdDialog");
    this._features = $injector.get("Config").features;
    this.$onInit = this._init;
  }

  /// Private Functions ///
  /*
   * The following are passed in through directive attributes, and are not available until $onInit
   *   this.averageHrData {Array}
   *   this.timeZone {String}
   *   this.parentElement (element ID. Optional)
   */
  _init() {
    angular.element(() => {
      const parent = this.parentElement || "rawHrDataGraph";
      const height = 500;
      const width = this._$document[0].getElementById(parent).offsetWidth;
      const svg = this._createSvg(width, height, parent);
      const margin = {top: 10, right: 10, bottom: 50, left: 30};
      const plotArea = {
        width: width - margin.left - margin.right,
        height: height - margin.top - margin.bottom,
      };

      const xValues = this.averageHrData.map((pair) => pair.ts);
      const yValues = this.averageHrData.map((pair) => pair.bpm);

      const domainStartTime = d3.min(xValues);
      const domainEndTime = moment(domainStartTime).add(30, "minutes").valueOf();

      const xDomain = [domainStartTime, domainEndTime];
      const yDomain = [0, d3.max(yValues) * 1.1];
      const xRange = [margin.left, width - margin.right];
      const yRange = [height - margin.bottom, margin.top];

      const xScale = d3.scaleTime().domain(xDomain).range(xRange);
      const yScale = d3.scaleLinear().domain(yDomain).range(yRange);

      // Inverts the xScale
      const getTime = function getTime(xValue) {
        return xScale.invert(xValue).getTime();
      };

      const xAxisConfig = {
        axisFunc: d3.axisBottom,
        scale: xScale,
        ticks: d3.timeMinute.every(2),
        tickFormat: (datetime) => formatDateAndTime({datetime, zone: this.timeZone, format: "HH:mm"}),
      };

      const yAxisConfig = {
        axisFunc: d3.axisLeft,
        scale: yScale,
        tickValues: [0, 50, 100, 150, 200],
      };

      const vGridConfig = {
        axisFunc: d3.axisBottom,
        scale: xScale,
        tickSize: plotArea.height,
        ticks: d3.timeMinute.every(1),
        tickFormat: "",
      };

      const hGridConfig = {
        axisFunc: d3.axisRight,
        scale: yScale,
        tickSize: plotArea.width,
        ticks: 10,
        tickFormat: "",
      };

      // Draw elements
      const verticalGridLines = svg.append("g").call(this._getAxis(vGridConfig));
      const horizontalGridLines = svg.append("g").call(this._getAxis(hGridConfig));
      const drawnXAxis = svg.append("g").call(this._getAxis(xAxisConfig));
      const drawnYAxis = svg.append("g").call(this._getAxis(yAxisConfig));
      this._drawPlotLine(svg, this.averageHrData, this._valueLine(xScale, yScale));

      // Move elements to correct positions (account for margins, x-axis from top to bottom)
      verticalGridLines.attr("transform", `translate(0, ${margin.top})`);
      horizontalGridLines.attr("transform", `translate(${margin.left}, 0)`);
      drawnYAxis.attr("transform", `translate(${margin.left}, 0)`);
      drawnXAxis.attr("transform", `translate(0, ${height - margin.bottom})`);

      // Add classes for styling axes
      drawnXAxis.classed("axis axis-bottom", true);
      drawnYAxis.classed("axis axis-left", true);
      verticalGridLines.classed("grid", true);
      horizontalGridLines.classed("grid", true);

      // Change angle of x-axis labels
      drawnXAxis.selectAll("text").attr("transform", "rotate(-45)").style("text-anchor", "end");

      // Excluding Artifacts
      let mouseStartX;
      let dragEvent = false;
      const regions = [];
      const graphData = {
        svg,
        margin,
        plotArea,
        width,
        height,
        regions,
        getTime,
        xScale,
        domainStartTime,
        domainEndTime,
      };
      const excludeArtifactService = this._excludeArtifactService;
      excludeArtifactService.init(graphData, this.studyId);

      // Register Mouse events for exclude artifact
      svg.on(
        "mousedown",
        function mouseDownEvent(event) {
          const coords = d3.pointer(event);
          if (event.button === 0 && excludeArtifactService.isInsidePlotArea(graphData, coords)) {
            const regionToDeleteIndex = excludeArtifactService.findIndexOfClickedRegion(
              graphData.regions,
              coords
            );
            if (regionToDeleteIndex >= 0) {
              // Delete button was clicked
              const region = graphData.regions[regionToDeleteIndex];

              let confirmPromise = Promise.resolve();
              if (region.startTime < graphData.domainStartTime || region.endTime > graphData.domainEndTime) {
                confirmPromise = this._$mdDialog.show(
                  this._$mdDialog
                    .confirm()
                    .multiple(true) // This must be included on the child mdDialog when there are nested mdDialogs
                    .title("Are you sure you would like to delete this exclusion region?")
                    .htmlContent(
                      `<p class="warningMessage"><i class="material-icons-outlined dialogWarningIcon"> report_problem </i> ` +
                        `Some of this region cannot be displayed. Are you sure you would like to delete it?` +
                        `</p><p class="centeredText warningMessage">` +
                        `Start time: ${moment(region.startTime).format("HH:mm:ss")}` +
                        `<br>End time: ${moment(region.endTime).format("HH:mm:ss")}` +
                        `</p>`
                    )
                    .ariaLabel("Confirm deletion of exclusion region")
                    .targetEvent(event)
                    .ok("Delete")
                    .cancel("Cancel")
                );
              }

              confirmPromise
                .then(() => excludeArtifactService.deleteRegion(graphData, regionToDeleteIndex))
                .catch(() => {});
            } else {
              // Graph was clicked to click and drag a region
              dragEvent = true;
              [mouseStartX] = coords;
            }
          } else {
            dragEvent = false;
          }
        }.bind(this)
      );

      svg.on("mouseup", function mouseUpEvent(event) {
        if (!dragEvent || event.button !== 0) {
          return;
        }
        const coords = d3.pointer(event);
        const mouseX = coords[0];
        dragEvent = false;
        if (Math.abs(mouseStartX - mouseX) >= excludeArtifactService.MIN_ARTIFACT_REGION) {
          excludeArtifactService.addRegion(graphData, mouseStartX, mouseX);
        }
      });

      svg.on("mousemove", function mouseMoveEvent(event) {
        const coords = d3.pointer(event);
        const mouseX = coords[0];
        if (dragEvent) {
          if (excludeArtifactService.isInsidePlotArea(graphData, coords)) {
            excludeArtifactService.drawRegions(graphData, mouseStartX, mouseX);
          } else {
            // End the drag event if the mouse leaves the plot area
            dragEvent = false;
            excludeArtifactService.addRegion(graphData, mouseStartX, mouseX);
          }
        } else {
          // Identify if the mouse is hovering on any X icons
          const deleteIconHoveredIndex = excludeArtifactService.findIndexOfClickedRegion(
            graphData.regions,
            coords
          );

          graphData.regions.forEach((region) => {
            region.isHovered = false;
          });
          if (deleteIconHoveredIndex >= 0) {
            graphData.regions[deleteIconHoveredIndex].isHovered = true;
          }

          // Redraw all regions
          excludeArtifactService.drawRegions(graphData);
        }
      });
    });
  }

  _createSvg(width, height, parent) {
    return d3.select(`#${parent}`).append("svg").attr("height", height).attr("width", width);
  }

  _valueLine(xScale, yScale) {
    return d3
      .line()
      .x((d) => xScale(d.ts))
      .y((d) => yScale(d.bpm));
  }

  _getAxis({axisFunc, scale, tickSize = 0, tickFormat = null, tickValues = null, ticks = null}) {
    return axisFunc()
      .scale(scale)
      .tickSize(tickSize)
      .tickFormat(tickFormat)
      .ticks(ticks)
      .tickValues(tickValues);
  }

  _drawPlotLine(svg, hrData, lineFunc) {
    return svg.append("path").datum(hrData).attr("class", "line").attr("d", lineFunc);
  }
}
