/* eslint-env browser */

import angular from "angular";
import * as d3 from "d3";
import {DateTime, IANAZone} from "luxon";

import gantt from "../../../d3/gantt.js";
import {formatDateAndTime} from "../../components/DateAndTime/DateAndTime.jsx";

/* @ngInject */
export default class ArrhythmiaTimelineController {
  constructor($scope, $rootScope, $document, $element) {
    this._$document = $document;
    this._$element = $element;
    this._$scope = $scope;
    this._$rootScope = $rootScope;
    this.$onInit = this._init;
  }

  /// Public Functions ///

  get graphConfig() {
    const graphConfig = {
      "Daily Trend": {
        labelFrequency: d3.utcHour.every(4),
        startDate: this.dailyTrendStartTime,
        endDate: this.dailyTrendEndTime,
        dayOfStudyData: null,
        frequency: d3.utcHour.every(1),
        tickFormat: (datetime) => formatDateAndTime({datetime, zone: this.timeZone, format: "HH:mm"}),
      },
      Summary: {
        labelFrequency: d3.utcHour.every(4),
        startDate: this.summaryStartTime,
        endDate: this.summaryEndTime,
        dayOfStudyData: {
          firstDay: DateTime.fromISO(this.studyStartDate).setZone(this.timeZone).startOf("day"),
          lastDay: DateTime.fromISO(this.studyEndDate).setZone(this.timeZone).endOf("day"),
        },
        frequency: d3.utcHour.every(4),
        tickFormat: "",
      },
    };
    // Graphs on studies are formatted like Summary items
    graphConfig["Active Study"] = graphConfig.Summary;
    graphConfig["Completed Study"] = graphConfig.Summary;
    return graphConfig;
  }

  get dailyTrendStartTime() {
    return DateTime.fromMillis(this.heartRateTrend.boxPlots[0].startTime).setZone(this.timeZone);
  }

  get summaryStartTime() {
    return DateTime.fromISO(this.studyStartDate)
      .setZone(this.timeZone)
      .startOf("week", {useLocaleWeeks: true});
  }

  get dailyTrendEndTime() {
    return this.dailyTrendStartTime.plus({days: 1});
  }

  get summaryEndTime() {
    return DateTime.fromISO(this.studyEndDate).setZone(this.timeZone).endOf("week", {useLocaleWeeks: true});
  }

  get isDisabled() {
    if (!this.chartToggles) {
      return false;
    }
    return !this.chartToggles.arrhythmiaTimeline;
  }

  drawGraph() {
    const totalDays = this.endDate.diff(this.startDate, "days").toObject().days;
    const numberOfSections = Math.ceil(totalDays / 7);

    angular.element(() => {
      for (let i = 0; i < numberOfSections; i++) {
        this._deleteArrhythmiaTimelineByIndex(i);
        this._createSectionGraph(i, this.isDisabled);
      }
    });
  }

  /// Private Functions ///

  /*
   * The following are passed in through directive attributes, and are not available until $onInit
   *   this.heartRateTrend
   *   this.arrhythmiaData
   *   this.artifactRegions
   *   this.parentElementId
   *   this.reportId
   *   this.parentType
   *   this.studyStartDate
   *   this.studyEndDate
   *   this.isGeneratedReport
   *   this.chartToggles
   *   this.timeZone
   */
  _init() {
    if (!this.timeZone || !IANAZone.isValidZone(this.timeZone)) {
      this.timeZone = DateTime.local().toFormat("z");
    }

    // add all properties of graphConfig to "this"
    Object.assign(this, this.graphConfig[this.parentType]);

    this._setRhythmTypes(this.arrhythmiaData);

    const deregisterResize = this._$rootScope.$on("window-resize", () => {
      this.drawGraph();
    });
    this._$scope.$on("$destroy", deregisterResize);

    const deregisterRedraw = this._$rootScope.$on("redraw-chart-arrhythmiaTimeline", () => {
      this.drawGraph();
    });
    this._$scope.$on("$destroy", deregisterRedraw);

    const deregister = this._$rootScope.$on("report-graph-data-updated", (emittedEvent, reportId) => {
      if (reportId === this.reportId) {
        this.drawGraph();
        this._$scope.$apply();
      }
    });
    this._$scope.$on("$destroy", deregister);
    this.drawGraph();
  }

  _setRhythmTypes(data) {
    // Iterate through data and pull out each new type
    const rhythmTypesForEvents = [];
    data.forEach((eventData) => {
      const displayedName = this._getDisplayedArrhythmiaType(eventData.eventName);
      if (!rhythmTypesForEvents.includes(displayedName) && displayedName !== "Unreadable") {
        rhythmTypesForEvents.push(displayedName);
      }
    });
    this._organizeRhythmTypes(rhythmTypesForEvents);
  }

  _organizeRhythmTypes(rhythmTypesForEvents) {
    // Events will be sorted:
    //  - Instantaneous Events (Patient Activated, Pause)
    //  - Other Events (sorted)
    //  - Normal Events (sorted)
    //  - Unclassified
    const eventsDisplayedFirst = ["Patient Activated", "Pause"];
    const eventsDisplayedLast = ["Unclassified"];

    // Other Events are the miscellaneous classifications
    const otherEvents = rhythmTypesForEvents.filter((type) => {
      const isDisplayedFirst = eventsDisplayedFirst.includes(type);
      const isDisplayedLast = eventsDisplayedLast.includes(type);
      const isNormal = type.startsWith("Normal");
      return !isDisplayedFirst && !isDisplayedLast && !isNormal;
    });
    otherEvents.sort();

    const normalEvents = rhythmTypesForEvents.filter((type) => type.startsWith("Normal"));
    normalEvents.sort();

    // Merge arrays
    const organizedTypes = [...eventsDisplayedFirst, ...otherEvents, ...normalEvents, ...eventsDisplayedLast];

    // Final filter is to get rid of Patient Activated, Pause, and Normal if they aren't in arrhythmiaTypes
    this.arrhythmiaTypes = organizedTypes.filter((type) => rhythmTypesForEvents.includes(type));
    // This is so the timeline always has at least one row to prevent y coordinates from being calculated as NaN or undefined
    if (this.arrhythmiaTypes.length === 0) {
      this.arrhythmiaTypes.push("Normal");
    }
  }

  _getDisplayedArrhythmiaType(type) {
    let displayedType;
    switch (type) {
      case "Cardiac Pause":
        displayedType = "Pause";
        break;
      case "Bradycardia":
      case "Bradycardia Rate Change":
        displayedType = "Brady";
        break;
      case "Tachycardia":
      case "Tachycardia Rate Change":
        displayedType = "Tachy";
        break;
      case "Normal Sinus Rhythm":
        displayedType = "Normal";
        break;
      case "Atrial Fibrillation":
        displayedType = "AF";
        break;
      case "Artifact":
      case "Lead Off":
      case "Unreadable ECG Data":
        displayedType = "Unreadable";
        break;
      case null:
        displayedType = "Unclassified";
        break;
      default:
        displayedType = type;
    }
    return displayedType;
  }

  _createSectionGraph(index, isDisabled) {
    const sectionStartTime = this.startDate.plus({days: index * 7});
    let sectionEndTime;

    if (this.parentType === "Daily Trend") {
      sectionEndTime = this.endDate;
    } else {
      sectionEndTime = this.startDate.plus({days: (index + 1) * 7});
    }

    const artifactRegionsForSection = this.artifactRegions.map((region) => {
      return {
        startTime: new Date(region.startTime).getTime(),
        endTime: new Date(region.endTime).getTime(),
        eventName: "Artifact",
      };
    });
    const arrhythmiaDataForSection = this._getSectionEvents(sectionStartTime, sectionEndTime);
    const formattedDataForSection = this._formatSectionEvents([
      ...artifactRegionsForSection,
      ...arrhythmiaDataForSection,
    ]);

    const graphId = `arrhythmiaTimeLine${index}-${this.reportId}`;
    const graphClass = "arrhythmiaTimelineContainer";
    let appendToElement = this._$element[0];
    if (this.isGeneratedReport) {
      appendToElement = this._getElementWithRetries(`arrhythmia-timeline-section-${index}`);
    }
    this._createAndAppendDiv(graphId, graphClass, appendToElement);

    const itemTitleRow = this._$document[0].getElementById(this.parentElementId);

    const configuredGantt = gantt(itemTitleRow, graphId, this.dayOfStudyData, {
      taskTypes: this.arrhythmiaTypes,
      tickFrequency: this.frequency,
      tickFormat: this.tickFormat,
      labelFrequency: this.labelFrequency,
      timeDomainMode: "fixed",
      timeDomain: [sectionStartTime, sectionEndTime],
    });

    configuredGantt(formattedDataForSection, isDisabled);
  }

  /*
   * Include the event if the event starts, ends, or spans the section of time
   */
  _getSectionEvents(sectionStartTime, sectionEndTime) {
    return this.arrhythmiaData.filter((ecgEvent) => {
      const eventStartTime = new Date(ecgEvent.startTime);
      const eventEndTime = new Date(ecgEvent.endTime);
      const eventStartAfterStart = eventStartTime >= sectionStartTime;
      const eventStartBeforeEnd = eventStartTime <= sectionEndTime;
      const eventEndAfterStart = eventEndTime >= sectionStartTime;
      const eventEndBeforeEnd = eventEndTime <= sectionEndTime;
      const eventStartBeforeStart = eventStartTime <= sectionStartTime;
      const eventEndAfterEnd = eventEndTime >= sectionEndTime;

      return (
        (eventStartAfterStart && eventStartBeforeEnd) ||
        (eventEndAfterStart && eventEndBeforeEnd) ||
        (eventStartBeforeStart && eventEndAfterEnd)
      );
    });
  }

  _formatSectionEvents(arrhythmiaDataForSection) {
    return arrhythmiaDataForSection.map((ecgEvent) => {
      const formattedData = {
        startDate: new Date(ecgEvent.startTime),
        endDate: new Date(ecgEvent.endTime),
        taskName: this._getDisplayedArrhythmiaType(ecgEvent.eventName),
      };

      if (formattedData.taskName === "Patient Activated" || formattedData.taskName === "Pause") {
        formattedData.display = "circle";
      }

      return formattedData;
    });
  }

  _deleteArrhythmiaTimelineByIndex(index) {
    let parentElement = this._$element[0];
    if (this.isGeneratedReport) {
      parentElement = this._$document[0].getElementById(`arrhythmia-timeline-section-${index}`);
    }
    const elementToRemove = document.getElementById(`arrhythmiaTimeLine${index}-${this.reportId}`);
    if (elementToRemove) {
      parentElement.removeChild(elementToRemove);
    }
  }

  _createAndAppendDiv(id, className, parentElement) {
    const div = document.createElement("div");
    div.setAttribute("id", id);
    div.setAttribute("class", className);
    parentElement.appendChild(div);
  }

  _getElementWithRetries(id, attempts = 5) {
    let element;
    for (let i = 0; i < attempts && !element; i++) {
      element = this._$document[0].getElementById(id);
    }
    return element;
  }
}
