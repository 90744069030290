/* eslint-env browser */
import angular from "angular";
import * as d3 from "d3";
import {DateTime, IANAZone} from "luxon";
import moment from "moment";

import ganttBoxPlot from "../../../d3/ganttBoxPlot.js";
import {formatDateAndTime} from "../../components/DateAndTime/DateAndTime.jsx";
import rawHrDataPug from "../../dialogs/rawHrData/rawHrData.pug";

/* @ngInject */
export default class HeartRateTrendController {
  constructor($scope, $document, $injector, $element, $mdDialog) {
    this._$document = $document;
    this._$element = $element;
    this._$scope = $scope;
    this._$mdDialog = $mdDialog;
    this.features = $injector.get("Config").features;
    this._$rootScope = $injector.get("$rootScope");

    this.$onInit = this._init;
  }

  /// Public Functions ///

  get graphConfig() {
    const graphConfig = {
      "Daily Trend": {
        boxOffsetMs: 2 * 60 * 1000,
        boxWidthMs: 26 * 60 * 1000,
        labelFrequency: d3.utcHour.every(4),
        startDate: this.dailyTrendStartTime,
        endDate: this.dailyTrendEndTime,
        frequency: d3.utcHour.every(1),
        dayOfStudyData: null,
        tickFormat: (datetime) => formatDateAndTime({datetime, zone: this.timeZone, format: "HH:mm"}),
      },
      Summary: {
        boxOffsetMs: 15 * 60 * 1000,
        boxWidthMs: 210 * 60 * 1000,
        labelFrequency: d3.utcHour.every(4),
        startDate: this.summaryStartTime,
        endDate: this.summaryEndTime,
        dayOfStudyData: {
          firstDay: DateTime.fromISO(this.studyStartDate).setZone(this.timeZone).startOf("day"),
          lastDay: DateTime.fromISO(this.studyEndDate).setZone(this.timeZone).endOf("day"),
        },
        frequency: d3.utcHour.every(4),
        tickFormat: "",
      },
    };
    // Graphs on studies are formatted like Summary items
    graphConfig["Active Study"] = graphConfig.Summary;
    graphConfig["Completed Study"] = graphConfig.Summary;
    return graphConfig;
  }

  get max() {
    const actualMax = d3.max(this.heartRateTrend.boxPlots, (dataSet) => {
      return dataSet.boxPlotPoints[4];
    });
    const maxPlusMarginMultiplier = 1.1;
    return actualMax * maxPlusMarginMultiplier;
  }

  get dailyTrendStartTime() {
    return DateTime.fromMillis(this.heartRateTrend.boxPlots[0].startTime).setZone(this.timeZone);
  }

  get summaryStartTime() {
    return DateTime.fromISO(this.studyStartDate)
      .setZone(this.timeZone)
      .startOf("week", {useLocaleWeeks: true});
  }

  get dailyTrendEndTime() {
    return this.dailyTrendStartTime.plus({days: 1});
  }

  get summaryEndTime() {
    return DateTime.fromISO(this.studyEndDate).setZone(this.timeZone).endOf("week", {useLocaleWeeks: true});
  }

  get isDisabled() {
    if (!this.chartToggles) {
      return false;
    }
    return !this.chartToggles.heartRateTrend;
  }

  /// Private Functions ///

  /*
   * The following are passed in through directive attributes, and are not available until $onInit
   *   this.heartRateTrend
   *   this.arrhythmiaData
   *   this.artifactRegions
   *   this.parentElementId
   *   this.reportId
   *   this.parentType
   *   this.studyStartDate
   *   this.studyEndDate
   *   this.isGeneratedReport
   *   this.enrollmentId
   *   this.studyId
   *   this.chartToggles
   *   this.timeZone
   */
  _init() {
    if (!this.timeZone || !IANAZone.isValidZone(this.timeZone)) {
      this.timeZone = DateTime.local().toFormat("z");
    }

    // add all properties of graphConfig to "this"
    Object.assign(this, this.graphConfig[this.parentType]);

    const eventTypesToExclude = ["Unreadable ECG Data", "Artifact", "Lead Off"];
    this.unreadableEcgData = this.arrhythmiaData.filter((event) => {
      return eventTypesToExclude.includes(event.eventName);
    });

    const deregisterResize = this._$rootScope.$on("window-resize", () => {
      this.drawGraph();
    });
    this._$scope.$on("$destroy", deregisterResize);

    const deregisterRedraw = this._$rootScope.$on("redraw-chart-heartRateTrend", () => {
      this.drawGraph();
    });
    this._$scope.$on("$destroy", deregisterRedraw);

    const deregister = this._$rootScope.$on("report-graph-data-updated", (emittedEvent, reportId) => {
      if (reportId === this.reportId) {
        this.drawGraph();
        this._$scope.$apply();
      }
    });
    this._$scope.$on("$destroy", deregister);
    this.drawGraph();
  }

  drawGraph() {
    let boxPlotsClickable = false;
    boxPlotsClickable = this._areBoxPlotsClickable(this.isGeneratedReport, this.parentType);

    const totalDays = this.endDate.diff(this.startDate, "days").toObject().days;
    const numberOfSections = Math.ceil(totalDays / 7);

    angular.element(() => {
      for (let i = 0; i < numberOfSections; i++) {
        this._deleteHeartRateTrendPlotByIndex(i);
        this._createSectionGraph(i, boxPlotsClickable, this.isDisabled);
      }
    });
  }

  _createSectionGraph(index, boxPlotsClickable, isDisabled = false) {
    let clickBoxPlotFunction = null;
    if (boxPlotsClickable) {
      clickBoxPlotFunction = this._showRawHrData.bind(this);
    }
    const sectionStartTime = this.startDate.plus({days: index * 7});
    let sectionEndTime;

    if (this.parentType === "Daily Trend") {
      sectionEndTime = this.endDate;
    } else {
      sectionEndTime = this.startDate.plus({days: (index + 1) * 7});
    }

    const boxPlotData = this._getSectionHrData(sectionStartTime, sectionEndTime);
    const averageHeartRates = this._getAverageHrData(sectionStartTime, sectionEndTime);
    const artifactRegions = this.artifactRegions.map((region) => {
      return {
        startTime: new Date(region.startTime).getTime(),
        endTime: new Date(region.endTime).getTime(),
        eventName: "Artifact",
      };
    });
    const unreadableData = artifactRegions.concat(
      this._getSectionUnreadableEcgData(sectionStartTime, sectionEndTime)
    );

    const graphId = `heartRateTrendPlot${index}-${this.reportId}`;
    const graphClass = "boxPlotContainer";
    let appendToElement = this._$element[0];
    if (this.isGeneratedReport) {
      appendToElement = this._getElementWithRetries(`hr-trend-section-${index}`);
    }
    this._createAndAppendDiv(graphId, graphClass, appendToElement);

    const itemTitleRow = this._$document[0].getElementById(this.parentElementId);

    const configuredGanttBoxPlot = ganttBoxPlot(itemTitleRow, graphId, this.dayOfStudyData, {
      maxValueY: this.max,
      boxOffsetMs: this.boxOffsetMs,
      boxWidthMs: this.boxWidthMs,
      tickFrequency: this.frequency,
      tickFormat: this.tickFormat,
      labelFrequency: this.labelFrequency,
      timeDomainMode: "fixed",
      timeDomain: [sectionStartTime, sectionEndTime],
    });

    configuredGanttBoxPlot(boxPlotData, averageHeartRates, unreadableData, clickBoxPlotFunction, isDisabled);
  }

  /*
   * Include the event if the event starts, ends, or spans the section of time
   */
  _getSectionHrData(sectionStartTime, sectionEndTime) {
    return this.heartRateTrend.boxPlots.filter((dataPoint) => {
      return (
        (dataPoint.startTime >= sectionStartTime && dataPoint.startTime <= sectionEndTime) ||
        (dataPoint.startTime + this.boxOffsetMs + this.boxWidthMs >= sectionStartTime &&
          dataPoint.startTime + this.boxOffsetMs + this.boxWidthMs <= sectionEndTime)
      );
    });
  }

  _getAverageHrData(sectionStartTime, sectionEndTime) {
    return this.heartRateTrend.averageHeartRates.filter((dataPoint) => {
      return dataPoint.mt >= sectionStartTime && dataPoint.mt <= sectionEndTime;
    });
  }

  _getSectionUnreadableEcgData(sectionStartTime, sectionEndTime) {
    return this.unreadableEcgData.filter((event) => {
      const eventStartTime = moment(event.startTime);
      const eventEndTime = moment(event.endTime);
      return (
        (eventStartTime >= sectionStartTime && eventStartTime <= sectionEndTime) ||
        (eventEndTime >= sectionStartTime && eventEndTime <= sectionEndTime) ||
        (eventStartTime <= sectionStartTime && eventEndTime >= sectionEndTime)
      );
    });
  }

  _deleteHeartRateTrendPlotByIndex(index) {
    let parentElement = this._$element[0];
    if (this.isGeneratedReport) {
      parentElement = this._$document[0].getElementById(`hr-trend-section-${index}`);
    }
    const elementToRemove = document.getElementById(`heartRateTrendPlot${index}-${this.reportId}`);
    if (elementToRemove) {
      parentElement.removeChild(elementToRemove);
    }
  }

  _createAndAppendDiv(id, className, parentElement) {
    const div = document.createElement("div");
    div.setAttribute("id", id);
    div.setAttribute("class", className);
    parentElement.appendChild(div);
  }

  _areBoxPlotsClickable(isGeneratedReport, parentType) {
    return !isGeneratedReport && parentType === "Daily Trend" && !this.isDisabled;
  }

  _showRawHrData(startTime) {
    setTimeout(this._fixMultiPopupBackDrop, 20, "Exclude artifact regions");

    return this._$mdDialog.show({
      controller: "RawHrDataController",
      controllerAs: "rawHrCtl",
      template: rawHrDataPug(),
      locals: {
        enrollmentId: this.enrollmentId,
        studyId: this.studyId,
        timeZone: this.timeZone,
        startTime,
      },
      multiple: true,
    });
  }

  _getElementWithRetries(id, attempts = 5) {
    let element;
    for (let i = 0; i < attempts && !element; i++) {
      element = this._$document[0].getElementById(id);
    }
    return element;
  }

  _fixMultiPopupBackDrop(textToSearchFor) {
    const dialog = Array.from(document.getElementsByClassName("md-dialog-container")).find((e) =>
      e.textContent.toUpperCase().includes(textToSearchFor.toUpperCase())
    );
    let computedStyle = window.getComputedStyle(dialog);
    let currentIndex = Number(computedStyle.getPropertyValue("z-index"));
    dialog.style["z-index"] = `${currentIndex + 2}`;

    const dialogBackdrop = document.getElementsByClassName("md-dialog-backdrop")[0];
    computedStyle = window.getComputedStyle(dialogBackdrop);
    currentIndex = Number(computedStyle.getPropertyValue("z-index"));
    dialogBackdrop.style["z-index"] = `${currentIndex + 2}`;
  }
}
