import pugFile from "./averageHeartRateGraph.pug";

export default function averageHeartRateGraphDirective() {
  return {
    scope: {},
    controller: "AverageHeartRateGraphController",
    controllerAs: "averageHeartRateGraph",
    template: pugFile(),
    bindToController: {
      averageHrData: "=",
      studyId: "=",
      timeZone: "=",
    },
  };
}
